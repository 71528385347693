import React from "react";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
// import { HierarchicalDropdown } from "components/shared/HierarchicalDropdown";
import { SLHierarchicalDropdown } from "components/shared/SLHierarchicalDropdown";
import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer";
import GLOBAL_CONFIG from "config";
import LABELS from "labels";
import RichEditor from "components/shared/RichEditor/";
import { NudgePopUp } from "components/shared/NudgePopUp";
import { Taxonomy } from "containers/Taxonomy";
import { Tooltip } from "components/shared/Tooltip";
import { getTaggedTaxonomyConfig } from "../KnowledgePage.config";
import GLOBAL_CONSTANTS from "globalConstants";
import { CASE_METADATA } from "redux/constants";
import { UploadFile } from "components/shared/UploadFile";
import LabelWithAdditionalText from "components/shared/LabelWithAdditionalText/LabelWithAdditionalText";
import CaseSearchItem from "components/CaseSearchItem/CaseSearchItem";
import SearchBox from "components/shared/SearchBox/SearchBox";
// import { PeopleSelector } from "components/PeopleSelector";
import SupportingLinks from "components/SupportingLinks/AddSupportingLinks";
import { bindActionCreators } from "redux";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import {
  removeSpaces,
  replaceInLabel,
  setSectorOwner,
  isSectorTopicOwnerSelected,
  getmyCurrentLocation,
  isFileTypePPT,
  getNumbersOfWords,
  getLengthOfMultiArrayWithPoly,
  validateCaseSearch,
  checkIfInList,
  isObjEmpty
} from "utils/helpers/helpers";
import { getUserId } from "utils/auth/auth";
import warningIcon from "assets/images/warning/warning.svg";
import infoicon from "assets/images/info.svg";
import Image from "components/shared/Image";
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import { selectors as caseMetadataSelectors } from "redux/reducers/caseMetadata/caseMetadata_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";

// Adobe Analytics file
import ANALYTICS from "utils/analytics/analytics";
import {
  DTMRULE,
  PAGES,
  PRIMARY_CATEGORY,
  TRIGGERS,
  MATERIAL_STATUS,
  SCREEN_NAMES
} from "utils/analytics/analytics_constants";
import "./KPStep1.scss";
import { InputCounter } from "components/shared/InputCounter/InputCounter";
import { getFromLocalStorageAsJSON } from "utils/localStorage/localStorage";
import { getDescriptionCharLength } from "components/shared/RichEditor/RichEditor.helper.js"

const { STEP1, STEP2, STEP3, ON_CHANGE_MDP_STATUS: { ADD }, ENTITLEMENTS: { KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT } } = GLOBAL_CONSTANTS;
const { THUMBSUPDOWN: { THUMBSUP } } = LABELS;
const {
  SEARCH_CASES,
} = CASE_METADATA;

class KPStep1Container extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      field_title: "",
      preFill: false,
      isKpTypeInternelSelected: false,
      descriptionLength: 0,
      selectedCase: props.associatedCases ? props.associatedCases : [],
      isAssociatedCaseUpdated: false,
      isUnsupportedFileError: false,
    };
    this.errorTimer = null;
  }

  componentDidMount = async () => {
    const {
      handleChange,
      mainKPState: {
        title,
        caseNumbers,
        kpOwner,
        uniqueId,
        file,
        documentPath,
        id,
        description
      },
      getCasesMetaDetails,
      getTagsSuggestions,
      callTaggingStatus,
      callTaskRegister,
      setIsApprovedByMdpShow,
      validationCheck,
      isApprovalMDPFieldShow,
      initializeTitleNudge
    } = this.props;
    const { SUGGESTIONS_TEXONOMY } = GLOBAL_CONSTANTS;

    const { IS_RECOMMENDED_TAGGING_ON } = GLOBAL_CONFIG;
    if (!id) {
      this.setState({
        selectedCase: [],  
      });
    }
    this.setState({
      field_title: title,
      descriptionLength: getDescriptionCharLength(description)
    });
    ANALYTICS.page.initialize({ pageName: PAGES.KP_CREATION, category: PRIMARY_CATEGORY.KP, trackPageLoad: true, pageId: { KCpage: { id } }, isIdPresent: id ? true : false });
    if (typeof kpOwner[0] === 'undefined' && kpOwner !== 0) {
      const hrId = await getUserId();
      handleChange([hrId], "kpOwner", STEP1, null, false);
    }
    if (caseNumbers.length) {
      getCasesMetaDetails(caseNumbers);
      getTagsSuggestions(caseNumbers, SUGGESTIONS_TEXONOMY);
    }
    if (IS_RECOMMENDED_TAGGING_ON && isFileTypePPT(file?.name)) {
      if (!!uniqueId && uniqueId !== "0") {
        callTaggingStatus(uniqueId);
      } else {
        callTaskRegister(documentPath);
      }
    }
    const isApprovedByMdpShow = isApprovalMDPFieldShow();

    if (!isApprovedByMdpShow) {
      handleChange("", "isMdpApprovalRequired", STEP1, null, true);
    }

    setIsApprovedByMdpShow(isApprovedByMdpShow, () => {
      //Below code is to show Nudge icon on title at the time of page load
      initializeTitleNudge(title);
      validationCheck(STEP1);
    });


  }

  componentDidUpdate(prevProps, prevState) {
    const {
      mainKPState: {
        isInternal,
        knowledgeType,
        isMdpApprovalRequired
      },
      setIsApprovedByMdpShow,
      isApprovalMDPFieldShow
    } = this.props;

    const {
      mainKPState: {
        isInternal: prevIsInternal,
        knowledgeType: prevKnowledgeType,
        isMdpApprovalRequired: prevIsMdpApprovalRequired,
      }
    } = prevProps;

    const { isAssociatedCaseUpdated, isKpTypeInternelSelected } = this.state;

    if (prevIsInternal !== isInternal && isInternal) {
      this.updateMainKPStateObjPropData();
    }
    if (this.props.associatedCases !== prevProps.associatedCases && !isAssociatedCaseUpdated) {
      this.setState({
        selectedCase: this.props.associatedCases,
        isAssociatedCaseUpdated: true
      });
    }

    if (knowledgeType?.id !== prevKnowledgeType?.id ||
      isMdpApprovalRequired !== prevIsMdpApprovalRequired ||
      isKpTypeInternelSelected !== prevState?.isKpTypeInternelSelected) {
      const isApprovedByMdpShow = isApprovalMDPFieldShow();
      setIsApprovedByMdpShow(isApprovedByMdpShow)
    }
  }

  updateMainKPStateObjPropData = () => {
    const { updateMainKPStateObjPropData } = this.props;

    updateMainKPStateObjPropData('practiseAreaTags', ['ipaTags', 'fpaTags']);
  }

  titleBlurHandler = (data, title) => {
    const { handleChange } = this.props;
    const trimmedName = removeSpaces(data);
    this.setState({
      field_title: trimmedName
    });
    // update the state - with data
    handleChange(trimmedName, title);
  };

  onChangeHandler = (value) => {
    // update the state - with data
    this.setState({
      field_title: value,
      preFill: true
    });
  };


  descriptionBlurHandler = (obj) => {
    const { data, descriptionLength } = obj;
    const { handleChange } = this.props;
    this.setState({
      descriptionLength
    })
    this.props.handleOnDescriptionChange(descriptionLength)
    handleChange(data, "description", STEP1, null, true);
  };

  onDescriptionChangeHandler = (descriptionLength) => {
    this.setState({
      descriptionLength
    })
  }

  sendUrlDataToApi = (linkdata) => {
    const { handleChange } = this.props;
    //renaming the keys as component sends name-value pairs, api needs address-url pairs
    if (linkdata.length) {
      var renamedLinks = linkdata.map(link => ({ title: link.name, address: link.value }));
      handleChange(renamedLinks[0], "materialUrl", STEP1);
    }
  };
  removeUrlDataFromApi = () => {
    const { handleChange } = this.props;
    handleChange({ title: "", address: "" }, "materialUrl", STEP1);
  }
  handleUnsupportedFileError = (value) => {
    clearTimeout(this.errorTimer);
    this.setState({ isUnsupportedFileError: value });
    if (value) {
      this.errorTimer = setTimeout(() => {
        this.setState({ isUnsupportedFileError: false });
      }, 15000)
    }
  }
  handleFileChange = (file, hasFile, event) => {
    const { mainKPState: { id }, handleS3Upload } = this.props;
    const { SUPPORTED_FILE_TYPES } = GLOBAL_CONFIG;
    let isFileSupp = false;
    if (file && file.name) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const { handleChange } = this.props;

      const isAutoSave = !id;
      const isSupportedFile = SUPPORTED_FILE_TYPES.some(item => item === fileExtension);
      if (isSupportedFile) {
        isFileSupp = true;
        handleChange(file, "file", STEP1, () => {
          if (id) {
            handleS3Upload(id);
          }
        }, isAutoSave);
      }
      else {
        event.target.value = '';
        this.handleUnsupportedFileError(true);
        isFileSupp = false;
      }
    }
    const { handleAnalyticsUpload } = this.props;
    handleAnalyticsUpload(isFileSupp, hasFile);
    // if (!!hasFile) {
    //   const { handleReplaceFile } = this.props;
    //   handleReplaceFile();
    // }
  }

  handleCaseNumberChange = (data, caseNumbers, mdpName, status = ADD) => {

    const { handleChange, getCasesMetaDetails, getTagsSuggestions, resetTagsSuggestions } = this.props;
    let updatedMdpArray = [];
    const { mdphrid } = data;
    if (mdphrid && !!caseNumbers && status === ADD) {
      if (mdpName.indexOf(mdphrid) !== -1) {
        updatedMdpArray = mdpName
      } else {
        updatedMdpArray = [...mdpName, mdphrid]
      }
    } else {
      updatedMdpArray = mdpName
    }
    handleChange(caseNumbers, "caseNumbers", STEP1, () => {
      handleChange(updatedMdpArray, "mdpName", STEP2, () => {
        if (caseNumbers.length) {
          getCasesMetaDetails(caseNumbers);
        }
      }, true);
    }, true);

    if (caseNumbers.length) {
      getTagsSuggestions(caseNumbers, "Taxonomy");
    } else {
      resetTagsSuggestions();
    }
  }

  addIsOwnerField = (data, idselected) => {
    !!data.length && Object.keys(data).forEach((key) => {
      data[key].isOwner = data[key].id === idselected;
    });
    return data;
  }

  getIdOfSelectedSector = (data) => {
    let selectedId = "";
    !!data.length && Object.keys(data).forEach((key) => {
      if (data[key].isOwner !== "undefined" && !!data[key].isOwner) {
        selectedId = data[key].id;
      }
    });
    return selectedId;
  }

  handleTaxonomyChange = (data) => {
    const { mainKPState: { practiseAreaTags }, handleChange } = this.props;
    if (Object.keys(practiseAreaTags[0]).length) {
      var { ipaTags, fpaTags, bcgInternals } = practiseAreaTags[0];
    }
    // get selection from kpmain state
    let idselected = this.getIdOfSelectedSector(ipaTags) || this.getIdOfSelectedSector(fpaTags) || this.getIdOfSelectedSector(bcgInternals);
    let paTagsOwner = false;
    let paTags = [{
      "ipaTags": [],
      "fpaTags": [],
      "bcgInternals": []
    }];
    if (data[0].length || data[1].length || data[2].length) {
      let ipatags = data[0].length ? this.addIsOwnerField(data[0], idselected) : [];
      let fpatags = data[1].length ? this.addIsOwnerField(data[1], idselected) : [];
      let bcgInternals = data[2].length ? this.addIsOwnerField(data[2], idselected) : [];
      paTags = [{
        "ipaTags": ipatags,
        "fpaTags": fpatags,
        "bcgInternals": bcgInternals,
      }];
    }

    paTagsOwner = isSectorTopicOwnerSelected(paTags);

    handleChange(paTags, "practiseAreaTags", STEP3, () => {
      handleChange(paTagsOwner, "paTagsOwner", STEP3, null, false);
    });

  }

  handleRadioChange = (value) => {
    const { handleChange, resetTagsSuggestions } = this.props;
    handleChange(value, "isRelatedToCase", STEP1, () => {
      handleChange([], "mdpName", STEP2, () => {
        handleChange([], "caseNumbers", STEP1, () => {
          if (value === false) {
            this.setState({ selectedCase: [] }, () => {
              resetTagsSuggestions();
            }, true);
          }
        });
      });
    }, true);
  };

  handleMdpApprovedChange = (value) => {
    const { handleChange } = this.props;
    handleChange(value, "isMdpApprovalRequired", STEP1, null, true);
  }

  handlePillSelection = (pillTopicId) => {
    const { mainKPState: { practiseAreaTags }, handleChange } = this.props;
    let { ipaTags: ipatags, fpaTags: fpatags, bcgInternals } = practiseAreaTags[0];
    let paTagsOwner = false;
    let paTags = [];
    ipatags = setSectorOwner(ipatags, pillTopicId);
    fpatags = setSectorOwner(fpatags, pillTopicId);
    bcgInternals = setSectorOwner(bcgInternals, pillTopicId);

    paTags = [{ 'ipaTags': ipatags, 'fpaTags': fpatags, 'bcgInternals': bcgInternals }];
    paTagsOwner = isSectorTopicOwnerSelected(paTags);

    handleChange(paTags, "practiseAreaTags", STEP3, () => {
      handleChange(paTagsOwner, "paTagsOwner", STEP3, null, false)
    });
  }

  taxonomyAnalyticsCallback = (subId, pageId, triggerName, screenName, typeAheadObj) => {
    let analyticsObj = {
      page: {
        pageInfo: {
          pageName: PAGES.KP_PAGES,
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      collection: {
        trigger: triggerName,
        screenName: screenName,
        subTaxonomyID: subId
      },
      KCpage: {
        id: pageId
      }
    };

    if (typeAheadObj) {
      const {
        searchTerm, selectedOptionValue, selectedOptionRank
      } = typeAheadObj;
      analyticsObj["search"] = {
        term: searchTerm,
        typeHeadOptionText: selectedOptionValue,
        typeHeadOptionRank: selectedOptionRank
      }
    }
    ANALYTICS.kc.sendEventData(analyticsObj, DTMRULE.KP);
  }
  fileNameChangeClick = () => {
    const {
      mainKPState: {
        id: pageId
      }
    } = this.props;
    let analyticsObj = {
      page: {
        pageInfo: {
          pageName: PAGES.KP_PAGES,
          pageURL: getmyCurrentLocation()
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      suggestedTopicTitle: "NA",
      suggestedTopicPath: "NA",
      suggestedTopicID: "NA",
      suggestedTopicGroup: "NA",
      collection: {
        trigger: "NA",
        screenName: "Edit Names",
        subTaxonomyID: "NA"
      },
      tpof: {
        topicLevel: "NA"
      },
      search: {
        term: "NA",
        typeHeadOptionText: "NA",
        typeHeadOptionRank: "NA"
      },
      topic: {
        id: "NA",
      },
      KCpage: {
        id: pageId
      }
    };
    ANALYTICS.kc.sendEventData(analyticsObj, DTMRULE.KP);
  }

  handleSuggestionsClick = (taxonomyId, name, type) => {
    const {
      mainKPState: {
        id: pageId
      }
    } = this.props;
    let analyticsObj = {
      page: {
        pageInfo: {
          pageName: PAGES.KP_PAGES,
          pageURL: getmyCurrentLocation()
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      displayMode: "NA",
      materialID: "NA",
      materialURL: "NA",
      globalRelevance: "NA",
      materialPAStatus: "NA",
      suggestedTopicTitle: "NA",
      suggestedTopicPath: "NA",
      suggestedTopicID: "NA",
      suggestedTopicGroup: "NA",
      attachmentID: "NA",
      preFilterTerm: "NA",
      preOrderTerm: "NA",
      caseID: "NA",
      collection: {
        ID: "NA",
        subTaxonomyID: taxonomyId
      },
      trigger: "NA",
      search: {
        bestbetflag: "NA",
        term: "NA"
      },
      KCpage: {
        id: pageId
      }
    };
    ANALYTICS.kc.sendEventData(analyticsObj, DTMRULE.KP_PAGE_SUGGESTION_TAXONOMY_CLICK);
  }

  handleInternalKpType = () => {
    const { mainKPState: { status } } = this.props;
    const { KNOWLEDGE_STATUS: { REVIEW } } = GLOBAL_CONFIG;
    this.setState({
      isKpTypeInternelSelected: true
    }, () => {
      this.handleRadioChange(false);
      if (status !== REVIEW) {
        this.handleMdpApprovedChange("");
      }
    });
  }

  handleTypeChange = ({ id, name }) => {
    const { STEP2 } = GLOBAL_CONSTANTS;
    const { handleChange } = this.props;
    handleChange({ id, name }, "knowledgeType", STEP2);
    this.setFieldsOnKpType();
  }

  setFieldsOnKpType = () => {
    const { mainKPState: { isInternal } } = this.props;
    // const { KP_TYPE_BCG_INTERNAL: { id: bcgInternalsId } } = GLOBAL_CONFIG;
    if (isInternal) {
      this.handleInternalKpType();
    } else {
      this.setState({
        isKpTypeInternelSelected: false
      });
    }
  }

  errorMsg = (error, errorMsg) =>
    !error ? (
      <div className="kpstep1__error">
        <Image
          src={warningIcon}
          alt="icon warning"
          className="kpstep1__error--icon"
        />
        <span className="kpstep1__errormsg">{errorMsg}</span>
      </div>
    ) : null;

  /**
   * desc: Used to show error specific to title field
   * fox ex: lenegth validation, unique title check error
   * @param {*} errorState 
   * @param {*} localStateTitle 
   * @param {*} errorTextMsg 
   * @returns 
   */
  errorTitleMsg = (errorState, localStateTitle, errorTextMsg) => {
    const { titleConfig: {
      showUniqueTitleError
    } = {} } = this.props;
    let titleFlag = true;
    if (errorState && errorState['title'] && localStateTitle.length > 0 && !!errorTextMsg) {
      titleFlag = false
    } else if (showUniqueTitleError && !!errorTextMsg) {
      titleFlag = false
    }
    return this.errorMsg(titleFlag, errorTextMsg)
  }

  handleBlurFileNameChange = (newFileNameObj) => {
    const {
      handleChange
    } = this.props;
    handleChange(newFileNameObj, "filename", STEP1);
  }

  getValidationMessage = (isInternal, searchDiscoveryTags) => {
    const { step1NextBtnClicked } = this.props;
    const { KP: { STEP1: { MAXIMUM_SUBJECT_TAGS_MSG } } } = LABELS;
    const { KP_LIMITS: { SUBJECT_TAGS_MAXLIMIT } } = GLOBAL_CONFIG;

    if (isInternal && searchDiscoveryTags && searchDiscoveryTags.length < SUBJECT_TAGS_MAXLIMIT && step1NextBtnClicked) {
      return this.errorMsg(false, MAXIMUM_SUBJECT_TAGS_MSG)
    }

    return null
  }

  suggestionThumbsUpdownCallback = (selectedOptions = THUMBSUP, paRecommended) => {
    const { mainKPState: { id } } = this.props;
    const { RESULT_EXPLICIT_FEEDBACK } = DTMRULE;
    const { RECOMMENDING_TAGGING_SUBJECT_TAGS } = SCREEN_NAMES;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;
    const newAdobeData = {
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGES.KP_CONSUMPTION,
          pageURL: getmyCurrentLocation()
        },
      },
      KCpage: {
        id: id,
      },
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      displayMode: RECOMMENDING_TAGGING_SUBJECT_TAGS,
      resultRelevanceRating: selectedOptions
    };

    ANALYTICS.kc.sendEventData(newAdobeData, RESULT_EXPLICIT_FEEDBACK);
  }

  getValidationError = (isInternal, errorState, searchDiscoveryTags) => {
    if ((errorState && errorState['searchDiscoveryTags']) || this.getValidationMessage(isInternal, searchDiscoveryTags)) {
      return true;
    }
    return false;
  }

  searchCaseHandler = (keyword) => {
    this.fetchRelatedCases(keyword);
  }
  fetchRelatedCases = (keyword) => {
    const { searchRelatedCases } = this.props;
    const {
      CASES_SEARCH_PAGINATION: {
        PAGE_SIZE,
        PAGE_NUMBER,
      }
    } = GLOBAL_CONFIG;
    const payload = {
      caseNumber: keyword,
      doExactMatch: false,
      pageNumber: PAGE_NUMBER,
      pageSize: PAGE_SIZE,
    };
    searchRelatedCases(payload);
  }
  handleOnResultClick = (item) => {
    const { handleChange, getCasesMetaDetails, getTagsSuggestions, resetTagsSuggestions, mainKPState: { mdpName } } = this.props;
    const { id, caseNumber, sanitizedCaseTitle, billingMDP } = item;
    let updatedMdpArray = [];
    if (billingMDP) {
      if (mdpName.indexOf(billingMDP) !== -1) {
        updatedMdpArray = mdpName
      } else {
        updatedMdpArray = [...mdpName, billingMDP]
      }
    }
    else {
      updatedMdpArray = mdpName
    }

    const selectedItem = {
      caseGuid: id,
      caseNumber,
      caseTitle: sanitizedCaseTitle,
    };
    this.setState((prevState) => {
      const newSelectedCase = [...prevState.selectedCase, selectedItem];
      return { selectedCase: newSelectedCase };
    }, () => {
      const { selectedCase } = this.state;
      const caseNumbers = selectedCase?.map((caseItem) => caseItem.caseNumber);
      handleChange(caseNumbers, "caseNumbers", STEP1, () => {
        handleChange(updatedMdpArray, "mdpName", STEP2, () => {
          if (caseNumbers.length) {
            getCasesMetaDetails(caseNumbers);
          }
        }, true);
      }, true);

      if (caseNumbers.length) {
        getTagsSuggestions(caseNumbers, "Taxonomy");
      } else {
        resetTagsSuggestions();
      }
    });
  }
  crossClickCallback = (item) => {
    const { selectedCase } = this.state;
    const { handleChange, getCasesMetaDetails, getTagsSuggestions, resetTagsSuggestions } = this.props;
    const filteredCases = selectedCase?.filter((caseItem) => caseItem.caseNumber !== item.caseNumber);
    this.setState({
      selectedCase: filteredCases
    }, () => {
      const { selectedCase } = this.state;
      const caseNumbers = selectedCase?.map((caseItem) => caseItem.caseNumber);
      handleChange(caseNumbers, "caseNumbers", STEP1, () => {
        if (caseNumbers.length) {
          getCasesMetaDetails(caseNumbers);
        }

        if (caseNumbers.length) {
          getTagsSuggestions(caseNumbers, "Taxonomy");
        } else {
          resetTagsSuggestions();
        }

      }, true);
    });
  }

  isReview = () => {
    const { isPublishContentEntitlement } = this.props;
    return !isPublishContentEntitlement;
  }



  render() {
    // import labels
    const {
      CREATION: {
        TITLE_MIN_LENGTH_MESSAGE,
        TITLE_MIN_WITH_WORDS_LENGTH_MESSAGE,
        TITLE_MAX_LENGTH_MESSAGE,
        TEXT_MIN_LENGTH_MESSAGE
      },
      // METADATA: {
      //   PEOPLE_SELECTOR_PLACEHOLDER,
      // },
      KP: {
        STEP1: {
          TITLE_HEADING,
          KP_TITLE_PLACEHOLDER_TEXT,
          KNOWPAGE_TYPE_HEADING,
          ADDURL_HEADING,
          CASE_EXIST_ERROR_MSG,
          DESC_HEADING,
          // CREATED_BY_HEADING,
          TOOLTIPS: {
            ADD_URL,
            ADD_URL_POINTS_1,
            ADD_URL_POINTS_2,
          },
          RESTRICTED_YES,
          RESTRICTED_NO,
          IS_RELATED_TO_CASE,
          APPROVED_BY_MDP,
          APPROVED_MDP_YES,
          APPROVED_MDP_NO,
          APPROVED_MDP_NO_INFO,
          ADMIN_SELECTPA_HEADING,
          SECTION_ADMIN_HEADING,
          SECTION_SEARCH_HEADING,
          SEARCH_TAGS_HEADING,
          TYPESEARCH_PLACEHOLDER,
          SELECT_PLACEHOLDER,
          SUBJECT_SUGGESTION_HEADING_BY_FILEUPLOAD,
          SUBJECT_SUGGESTION_MODALHEADING,
          BST_SUGGESTION_HEADING,
          BST_SUGGESTION_MODALHEADING,
          BCG_INTERNALS_KP_TYPE_MESSAGE,
          MAXIMUM_TAGS_SELECTED,
          UNIQUE_TITLE_ERROR_MSG,
          SEARCH_RELATED,
          CASE_TOOLTIP,
          SEARCH_PLACEHOLDER,
          NO_RESULTS,
          REFINE_SEARCH,
          RESULTS_HEADING,
          UNSUPPORTED_FILE_FORMAT
        }
      },
      KNOWLEDGE_PRODUCT_NAME: {
        KP: KP_PRODUCT_NAME
      },
      TAXONOMY: {
        IPA_FPA_MODAL_SEARCH_PLACEHOLDER,
        TOOLTIPS: {
          IPA_TIP_MESSAGE,
          FPA_TIP_MESSAGE
        }
      },
      ADDITIONAL_LABELS,
      IMPACT_ON_SEARCH: {
        MEDIUM,
        HIGH
      },
      THUMBSUPDOWN: {
        HEADING_FEEDBACK
      },
      TOOLTIPS: {
        KP_TYPE,
        KP_UPLOADFILE,
        KP_CASENUMBER,
        KP_TITLE_1,
        KP_TITLE_2,
        KP_DESC_1,
        KP_DESC_2,
        KP_BST_1,
        KP_BST_2,
        KP_BST_CUST_1,
        KP_BST_CUST_2,
        KP_SUBJECT_1,
        KP_SUBJECT_2
      },
      METADATA: {
        MULTIPLE_TOPIC_PAGES_MAXLIMIT_MSG
      }
    } = LABELS;

    const {
      TBDBS: {
        SEARCH_FPA_IPA_INTERNAL,
        SEARCH_SUBJECT,
        BCG_INTERNAL
      }
    } = GLOBAL_CONSTANTS;

    // import configs
    const {
      KP_LIMITS: {
        KP_URLS_MAXLIMIT,
        MULTIPLE_TOPIC_PAGES_MAXLIMIT,
        SEARCH_TAGS_MAXLIMIT
      },
      BLACK_LISTED_LINKS,
      REGEX: { SUPP_LINKS_URL },
      WHITE_LISTED_LINKS,
      NUDGE_TIMEOUT_LIMITS: {
        DELAY_TIME
      },
      CP_CONTRIBUTION_LIMITS: { SEARCH_CASE_CHAR_LIMIT, SEARCH_CASES_LIMIT },
      KNOWLEDGE_STATUS: { REVIEW }
    } = GLOBAL_CONFIG;

    // import props
    const {
      mainKPState: {
        description,
        practiseAreaTags,
        searchDiscoveryTags,
        file,
        id,
        isURLAdded,
        mdpName,
        isRelatedToCase,
        caseNumbers,
        materialUrl,
        // kpOwner,
        documentPath,
        knowledgeType,
        isInternal,
        isMdpApprovalRequired,
        status
      },
      handleChange,
      fileUploadStatus,
      associatedCases,
      errorState,
      isFileExistOnS3,
      // getKnowledgeTypeData,
      tagsSuggestionsList,
      titleConfig: {
        showUniqueTitleError,
        preserveTitle
      },
      recommendedTagsDataByFile,
      subjectSearchStatus,
      subjectSearchLoadingStatus,
      expertTagsIds,
      subjectTagsIds,
      isLoading,
      thumbsUpDownAnalyticsCallback,
      searchResults,
      isSearchingCases,
      isApprovedByMdpShow,
      totalSearchResults
    } = this.props;
    if (practiseAreaTags) {
      var { ipaTags, fpaTags, bcgInternals } = practiseAreaTags[0];
    }

    // import state
    const { field_title: localStateTitle, isKpTypeInternelSelected, descriptionLength, selectedCase, isUnsupportedFileError } = this.state;

    //local variables
    const hasFile = (file && file.name) && documentPath;
    let item = [{
      name: materialUrl.title,
      value: materialUrl.address
    }];

    const { LABEL_VALUE } = GLOBAL_CONSTANTS;
    const { KP_LIMITS: { KPAGE_MIN_TITLE_CHARLIMIT, KPAGE_TITLE_CHARLIMIT, KPAGE_MIN_DESCRIPTION_CHARLIMIT } } = GLOBAL_CONFIG;
    const titlePlaceholderText = replaceInLabel(KP_TITLE_PLACEHOLDER_TEXT, LABEL_VALUE, KPAGE_MIN_TITLE_CHARLIMIT);
    const minTitleErrorMsg = replaceInLabel(TITLE_MIN_LENGTH_MESSAGE, LABEL_VALUE, KPAGE_MIN_TITLE_CHARLIMIT);
    const minTitleWithMinWordsError = replaceInLabel(TITLE_MIN_WITH_WORDS_LENGTH_MESSAGE, LABEL_VALUE, KPAGE_MIN_TITLE_CHARLIMIT);

    const maxTitleErrorMsg = replaceInLabel(TITLE_MAX_LENGTH_MESSAGE, LABEL_VALUE, KPAGE_TITLE_CHARLIMIT);
    let errorTitleMsg;
    if (!!localStateTitle?.length) {
      const wordsCount = getNumbersOfWords(localStateTitle);
      const minWordsCharchtersCondition = isInternal ?
        localStateTitle.length < KPAGE_MIN_TITLE_CHARLIMIT || wordsCount < 4 :
        localStateTitle.length < KPAGE_MIN_TITLE_CHARLIMIT;

      if (localStateTitle.length > KPAGE_TITLE_CHARLIMIT) {
        errorTitleMsg = maxTitleErrorMsg;
      } else if (minWordsCharchtersCondition) {
        errorTitleMsg = isInternal ? minTitleWithMinWordsError : minTitleErrorMsg;
      } else if (localStateTitle === preserveTitle && showUniqueTitleError) {
        /**
         * localStateTitle = 'title field current value'
         * preserveTitle = 'on every next click we are preserving the last title value
         * showUniqueTitleError = 'if title already exists in DB then we are passing this as true in props
         * 
         * This condition helps in showing the error after next click on step 1,
         * and user try to change the current title then we are removing the title already exist error
         * ans we try to add the same title again then we are showing this error again.
         */
        errorTitleMsg = UNIQUE_TITLE_ERROR_MSG;
      } else {
        errorTitleMsg = '';
      }
    }
    let minDescriptionErrorMsg = '';
    let descriptionError = false;
    if (errorState && errorState['description']) {
      if (descriptionLength === 0) {
        minDescriptionErrorMsg = errorState['description'].errormessage;
      } else if (isInternal && descriptionLength < KPAGE_MIN_DESCRIPTION_CHARLIMIT) {
        minDescriptionErrorMsg = replaceInLabel(TEXT_MIN_LENGTH_MESSAGE, LABEL_VALUE, KPAGE_MIN_DESCRIPTION_CHARLIMIT);
        descriptionError = true;
      }
    }

    const { bst: bstSuggestions } = tagsSuggestionsList;
    const { Subject: subjectSuggestions } = recommendedTagsDataByFile;
    const temp = checkIfInList(selectedCase, searchResults, CASE_EXIST_ERROR_MSG);
    const caseSearchResults = temp?.filter((item) => item.isRestrictedCase !== "Y");

    return (
      <div className="kpstep1">
        <Form.Group>
          <Form.Field>
            {isURLAdded === "0" &&
              <>
                <UploadFile
                  fileUploadStatus={fileUploadStatus}
                  file={file}
                  errorState={errorState}
                  hasFile={hasFile}
                  handleFileChange={this.handleFileChange}
                  isFileExistOnS3={isFileExistOnS3}
                  handleBlurFileNameChange={this.handleBlurFileNameChange}
                  disabled={isLoading}
                  fileNameChangeCallback={this.fileNameChangeClick}
                  handleUnsupportedFileError={this.handleUnsupportedFileError}
                  isUnsupportedFileError={isUnsupportedFileError}
                />
                <span className="kpstep1__uploadnote"><strong>Please Note:</strong> {KP_UPLOADFILE}</span>
                <div>{this.errorMsg(!isUnsupportedFileError, UNSUPPORTED_FILE_FORMAT)}</div>
              </>
            }
            {isURLAdded === "1" &&
              <>
                <Tooltip id="add_url" labelText={ADDURL_HEADING} labelClass="add-url">
                  {ADD_URL}
                  <ul><li>{ADD_URL_POINTS_1}</li><li>{ADD_URL_POINTS_2}</li></ul>
                </Tooltip>

                <div className={errorState && errorState['materialUrl'] ? 'customhighlight' : ''}>
                  <SupportingLinks
                    defaultState={item[0]?.name && item[0]?.value ? item : []}
                    limit={KP_URLS_MAXLIMIT}
                    getLinks={this.sendUrlDataToApi}
                    urlSanitization={BLACK_LISTED_LINKS}
                    urlWhiteList={WHITE_LISTED_LINKS}
                    urlValidation={SUPP_LINKS_URL}
                    hideFormOnReachingLimit={true}
                    deleteCallback={this.removeUrlDataFromApi}
                  />
                </div>
              </>
            }
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            {!!getFromLocalStorageAsJSON(GLOBAL_CONFIG.OKTA.STK) && (
              <SLHierarchicalDropdown
                label={KNOWPAGE_TYPE_HEADING}
                placeholder={SELECT_PLACEHOLDER}
                slectedValue={knowledgeType}
                onChange={this.handleTypeChange}
                onLoadCallback={this.setFieldsOnKpType()}
                className={errorState && errorState['knowledgeType'] ? 'customhighlight knowledgeDropdown' : 'knowledgeDropdown'}
                tooltipHtml={
                  <>
                    <p>
                      <a href={`${process.env.REACT_APP_MEDIA_URL}/-/media/Project/BCG/Websites/Knowledge-Management/Materials/DefinitionOfMaterialType.png`} target="_blank" rel="noopener noreferrer" >Click here</a> {KP_TYPE}</p>
                    <p>
                      <img src={`${process.env.REACT_APP_MEDIA_URL}/-/media/Project/BCG/Websites/Knowledge-Management/Materials/DefinitionOfMaterialType.png`} alt="page type" width="350px" height="200px" />
                    </p>
                  </>}
              />
            )}
            {this.errorMsg(!isKpTypeInternelSelected, BCG_INTERNALS_KP_TYPE_MESSAGE)}
          </Form.Field>
        </Form.Group>
        {isApprovedByMdpShow && <Form.Group className={`mdpApproved__wrapper ${status === REVIEW ? 'mdpApproved__wrapper__disabled' : ''}`}>
          <Form.Field>
            <label>{APPROVED_BY_MDP}</label>
            <div className="mdpApproved">
              <Form.Radio
                label={APPROVED_MDP_YES}
                name='approvedByMdp'
                value='Y'
                checked={isMdpApprovalRequired === 'Y'}
                onChange={(e, { value }) => {
                  this.handleMdpApprovedChange(value);
                }}
                className={errorState && errorState['isMdpApprovalRequired'] ? 'radioMdp_error' : ''}
              />
              <Form.Radio
                label={APPROVED_MDP_NO}
                name='approvedByMdp'
                value='N'
                checked={isMdpApprovalRequired === 'N'}
                onChange={(e, { value }) => {
                  this.handleMdpApprovedChange(value);
                }}
                className={errorState && errorState['isMdpApprovalRequired'] ? 'radioMdp_error' : ''}
              />
            </div>
            {<div className={`mdpNo__info ${(isMdpApprovalRequired === 'Y' || isMdpApprovalRequired === '') ? 'mdpNo__info__hidden' : ''}`}><img src={infoicon} />{APPROVED_MDP_NO_INFO}</div>}
          </Form.Field>
        </Form.Group>}
        <Form.Group>
          <Form.Field>
            <label className={isKpTypeInternelSelected ? "label__disabled" : ""}>{IS_RELATED_TO_CASE}</label>
            <div className="knowledgepage__isrestricted">
              <Form.Radio
                label={RESTRICTED_YES}
                name='isRelatedToCase'
                value={true}
                checked={isRelatedToCase}
                onChange={(e, { value }) => {
                  this.handleRadioChange(value);
                }}
                disabled={isKpTypeInternelSelected}
              />
              <Form.Radio
                label={RESTRICTED_NO}
                name='isRelatedToCase'
                value={false}
                checked={!isRelatedToCase}
                onChange={(e, { value }) => {
                  this.handleRadioChange(value);
                }}
                disabled={isKpTypeInternelSelected}
              />
            </div>
            <div className="search-related-container">
              <SearchBox
                handleOnSearch={this.searchCaseHandler}
                selectedCase={selectedCase}
                crossClickCallback={this.crossClickCallback}
                handleOutsideClick={this.clearSearchResults}
                placeHolder={SEARCH_PLACEHOLDER}
                label={SEARCH_RELATED}
                tooltipMessage={CASE_TOOLTIP}
                showErrorInResults={true}
                noResultsMessage={NO_RESULTS}
                tooManyResultsMessage={REFINE_SEARCH}
                maximumResults={SEARCH_CASES_LIMIT}
                maxLength={SEARCH_CASE_CHAR_LIMIT}
                searchResults={caseSearchResults}
                totalCount={totalSearchResults}
                resultsHeading={RESULTS_HEADING}
                isSearching={isSearchingCases}
                validateInput={validateCaseSearch}
                handleOnResultClick={this.handleOnResultClick}
                renderResult={item => {
                  const { sanitizedCaseTitle, caseNumber } = item;
                  return <CaseSearchItem caseNumber={caseNumber} caseTitle={sanitizedCaseTitle} />
                }}
                hideLabel={false}
                className={`searchBox ${!isRelatedToCase ? 'searchBox__disabled' : ''} ${errorState['caseNumbers'] ? 'searchBox__error' : ''}`}
                isMultipleCases={true}
              />
            </div>

          </Form.Field>
        </Form.Group>
        {/* <Form.Group>
          <Form.Field>
            <PeopleSelector
              maxLimit={1}
              disabledCross
              label={CREATED_BY_HEADING}
              placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
              className={errorState && errorState['kpOwner'] ? 'peopleselector__error' : ''}
              name="kpOwner"
              preFillData={kpOwner.length ? [kpOwner] : []}
              getSuggestionValueCallback={({ valueText, displayName }) => {
                ANALYTICS.kc.sendEventData({
                  search: {
                    term: valueText,
                    typeHeadOptionText: displayName,
                  },
                  page: {
                    pageInfo: {
                      pageName: PAGES.KP_PAGES,
                      // pageURL: ""
                    },
                    category: {
                      primaryCategory: PRIMARY_CATEGORY.KP
                    }
                  },
                  collection: {
                    trigger: TRIGGERS.TYPEAHEAD_CREATEDBY,
                    ID: id,
                    screenName: SCREEN_NAMES.KP_STEP_1,
                  },
                  KCpage: {
                    id: id
                  }
                }, DTMRULE.KP);
              }}
              onChange={(data) => {
                handleChange(data, "kpOwner", STEP1);
              }}
            />
          </Form.Field>
        </Form.Group> */}
        <Form.Group>
          <Form.Field>
            {/* <label>{TITLE_HEADING}</label> */}
            <LabelWithAdditionalText
              name={"title"}
              title={TITLE_HEADING}
              // addionalLabel={ADDITIONAL_LABELS}
              // value={HIGH}
              tooltipHtml={
                <>
                  <p>{KP_TITLE_1}</p>
                  <p>{KP_TITLE_2}</p>
                  <img src={`${process.env.REACT_APP_MEDIA_URL}/-/media/Project/BCG/Websites/Knowledge-Management/Materials/Title.png`} alt="page type" width="330px" height="200" />
                </>
              }
            />
            <NudgePopUp
              nodeName="title"
              value={localStateTitle}
              feedbackHeading={HEADING_FEEDBACK}
              finalSuccess={(text) => thumbsUpDownAnalyticsCallback(text, knowledgeType.isPARecommended, TITLE_HEADING)}
              heading={TITLE_HEADING}
              isInternal={isInternal}
              impactOnSearch={{
                label: ADDITIONAL_LABELS,
                type: HIGH
              }}
              nudgeConfig={{
                subjectSearchStatus,
                subjectSearchLoadingStatus,
              }}
              delay={DELAY_TIME}
            >
              <InputCounter
                maxLength={GLOBAL_CONFIG.KP_LIMITS.KPAGE_TITLE_CHARLIMIT}
                onBlur={(value) => this.titleBlurHandler(value, "title")}
                onChange={(value) => this.onChangeHandler(value)}
                value={localStateTitle}
                className={`titleField ${errorState && errorState['title'] ? 'input__error ' : ''}`}
                placeholder={titlePlaceholderText}
              />
            </NudgePopUp>
            {this.errorTitleMsg(errorState, localStateTitle, errorTitleMsg)}

          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            {/* <label>{DESC_HEADING}</label> */}
            <LabelWithAdditionalText title={DESC_HEADING} addionalLabel={ADDITIONAL_LABELS} value={MEDIUM}
              name={"desc"}
              tooltipHtml={
                <>
                  <p>{KP_DESC_1}</p>
                  <p>{KP_DESC_2}</p>
                  <img src={`${process.env.REACT_APP_MEDIA_URL}/-/media/Project/BCG/Websites/Knowledge-Management/Materials/Description.png`} alt="page type" width="330px" height="200" />
                </>
              }
            />
            <div className={minDescriptionErrorMsg ? 'richEditor customhighlight' : 'richEditor'}>
              <RichEditor
                updatedKey={id}
                initialState={description}
                //handleOnblur={(value) => handleChange(value, "description")}
                charLimit={GLOBAL_CONFIG.KP_LIMITS.KPAGE_RICHEDITOR_CHARLIMIT}
                formatDescription={true}
                onDescriptionChangeHandler={this.onDescriptionChangeHandler}
                handleOnblur={this.descriptionBlurHandler}
              />
            </div>
            {isInternal && descriptionError && this.errorMsg(!descriptionError, minDescriptionErrorMsg)}
          </Form.Field>
        </Form.Group>
        <Form.Group grouped>
          <Form.Field>
            <label>{SECTION_ADMIN_HEADING}</label>
            <div className="grouped-items">
              <Taxonomy
                label={ADMIN_SELECTPA_HEADING}
                placeholder={ADMIN_SELECTPA_HEADING}
                modalHeader={ADMIN_SELECTPA_HEADING}
                product={KP_PRODUCT_NAME}
                maxLimit={MULTIPLE_TOPIC_PAGES_MAXLIMIT}
                hideTagsIds={[...subjectTagsIds]}
                isApplicableForPolyHerarchi
                taxonomyName={[
                  {
                    label: "Industry",
                    key: "IPA",
                    disabled: isInternal ? true : false,
                    tooltipMessage: isInternal ? IPA_TIP_MESSAGE : ''
                  },
                  {
                    label: "Functional",
                    key: "FPA",
                    disabled: isInternal ? true : false,
                    tooltipMessage: isInternal ? FPA_TIP_MESSAGE : ''
                  },
                  {
                    label: "BCG Internal",
                    key: "BCG_INTERNAL"
                  }
                ]}
                onChange={(data) => {
                  this.handleTaxonomyChange(data)
                }}
                activeIndex={isInternal ? 2 : 0}
                preFillData={[
                  ipaTags,
                  fpaTags,
                  bcgInternals
                ]}
                className={errorState && errorState['practiseAreaTags'] ? 'txbase__error' : ''}
                openModalCallback={() => this.taxonomyAnalyticsCallback(null, id, TRIGGERS.BUTTON_IPA_FPA, SCREEN_NAMES.KP_STEP_3)}
                clickHandlerCallback={(subId) => this.taxonomyAnalyticsCallback(subId, id, TRIGGERS.TOPIC_SUBJECT_SELECTION, SCREEN_NAMES.KP_BST_TAGGING_MODAL)}
                deleteSelectedCallBack={(subId) => this.taxonomyAnalyticsCallback(subId, id, TRIGGERS.REMOVE_SELECTION, SCREEN_NAMES.KP_BST_TAGGING_MODAL)}
                submitHandlerCallback={() => this.taxonomyAnalyticsCallback(null, id, TRIGGERS.SUBMIT, SCREEN_NAMES.KP_BST_TAGGING_MODAL)}
                responseTypeSearchKeyName={{
                  id: "id",
                  name: "name",
                }}
                isShowParentTagsTooltip
                typeSearchplaceholder={IPA_FPA_MODAL_SEARCH_PLACEHOLDER}
                isShowNoResultInTypeAhead
                characterCount={2}
                taxonomyTypeSearchName={[
                  { searchKey: isInternal ? BCG_INTERNAL : SEARCH_FPA_IPA_INTERNAL }
                ]}
                showCustodian={true}
                tooltipHtml={
                  <>
                    <p>{KP_BST_1}</p>
                    <p>{KP_BST_2}</p>
                  </>
                }
                custodianTooltipHtml={
                  <>
                    <p>{KP_BST_CUST_1}</p>
                    <p>{KP_BST_CUST_2}</p>
                  </>
                }
                custodianClass={errorState && errorState['paTagsOwner'] ? 'customhighlight' : ''}
                onCustodianClick={this.handlePillSelection}
                informationIconsConfig={getTaggedTaxonomyConfig()}
                suggestions={{
                  tags: bstSuggestions,
                  heading: BST_SUGGESTION_HEADING,
                  modalHeading: BST_SUGGESTION_MODALHEADING,
                  maxLimitLabel: MAXIMUM_TAGS_SELECTED,
                  clickCallback: this.handleSuggestionsClick
                }}
                mainErrorMessage={this.errorMsg(!(errorState && errorState['practiseAreaTags'] && getLengthOfMultiArrayWithPoly([{
                  ipaTags,
                  fpaTags,
                  bcgInternals
                }]) > MULTIPLE_TOPIC_PAGES_MAXLIMIT), (MULTIPLE_TOPIC_PAGES_MAXLIMIT_MSG.replace("<configuredmaxlimit>", MULTIPLE_TOPIC_PAGES_MAXLIMIT)))}
                showTaxonomyTagCompletePath={true}
              />
            </div>
          </Form.Field>
        </Form.Group>

        <Form.Group grouped>
          <Form.Field>
            <label>{SECTION_SEARCH_HEADING}</label>
            <div className="grouped-items">
              <NudgePopUp
                nodeName="subjectTaxonomy"
                value={[searchDiscoveryTags]}
                onChange="onChange"
                heading={SECTION_SEARCH_HEADING}
                feedbackHeading={HEADING_FEEDBACK}
                finalSuccess={(text) => thumbsUpDownAnalyticsCallback(text, knowledgeType.isPARecommended, SECTION_SEARCH_HEADING)}
                checkSuggestionsOnChange
                delay={DELAY_TIME}
                nudgeConfig={{
                  hasFile,
                  subjectSuggestions,
                  isInternal
                }}
              >
                <Taxonomy
                  key={id}
                  label={SEARCH_TAGS_HEADING}
                  placeholder={SEARCH_TAGS_HEADING}
                  modalHeader={SECTION_SEARCH_HEADING}
                  product={KP_PRODUCT_NAME}
                  hideTagsIds={[...expertTagsIds]}
                  maxLimit={SEARCH_TAGS_MAXLIMIT}
                  taxonomyName={[
                    { label: "Subject", key: "SUBJECT" }
                  ]}
                  onChange={(data) => {
                    handleChange(data[0], "searchDiscoveryTags", STEP3);
                  }}
                  preFillData={[searchDiscoveryTags]}
                  className={this.getValidationError(isInternal, errorState, searchDiscoveryTags) ? 'txbase__error' : ''}
                  openModalCallback={() => this.taxonomyAnalyticsCallback(null, id, TRIGGERS.BUTTON_PA_SUBJECT, SCREEN_NAMES.KP_STEP_3)}
                  clickHandlerCallback={(subId) => this.taxonomyAnalyticsCallback(subId, id, TRIGGERS.TOPIC_SUBJECT_SELECTION, SCREEN_NAMES.KP_TOPIC_MODAL)}
                  deleteSelectedCallBack={(subId) => this.taxonomyAnalyticsCallback(subId, id, TRIGGERS.REMOVE_SELECTION, SCREEN_NAMES.KP_TOPIC_MODAL)}
                  submitHandlerCallback={() => this.taxonomyAnalyticsCallback(null, id, TRIGGERS.SUBMIT, SCREEN_NAMES.KP_TOPIC_MODAL)}
                  typeAheadHandlerCallback={(subId, searchTerm, selectedOptionValue, selectedOptionRank) => this.taxonomyAnalyticsCallback(subId, id, TRIGGERS.TYPEAHEAD_SUBJECT_SELECTION, SCREEN_NAMES.KP_TOPIC_MODAL, { searchTerm, selectedOptionValue, selectedOptionRank })}
                  responseTypeSearchKeyName={{
                    id: "id",
                    name: "name",
                  }}
                  isShowParentTagsTooltip
                  typeSearchplaceholder={TYPESEARCH_PLACEHOLDER}
                  characterCount={2}
                  taxonomyTypeSearchName={[
                    { searchKey: SEARCH_SUBJECT }
                  ]}
                  tooltipHtml={
                    <>
                      <p>{KP_SUBJECT_1}</p>
                      <p>{KP_SUBJECT_2}</p>
                    </>
                  }
                  suggestions={{
                    tags: isInternal ? [] : subjectSuggestions,
                    heading: SUBJECT_SUGGESTION_HEADING_BY_FILEUPLOAD,
                    modalHeading: SUBJECT_SUGGESTION_MODALHEADING,
                    showThumbs: true,
                    thumbsUpdownCallback: this.suggestionThumbsUpdownCallback
                    // maxLimitLabel: MAXIMUM_TAGS_SELECTED,
                    // clickCallback: this.handleSuggestionsClick
                  }}
                  suggestionResponseKeyName={{
                    id: "label_guid",
                    name: "label_value",
                    type: "label_type"
                  }}
                  validationErrorMessage={this.getValidationMessage(isInternal, searchDiscoveryTags)}
                />
              </NudgePopUp>
            </div>
          </Form.Field>
        </Form.Group>
      </div >
    );
  }
}

KPStep1Container.defaultProps = {
  validationCheck: () => { },
  initializeTitleNudge: () => { }
}

const mapStateToProps = (state) => ({
  getKnowledgeTypeData: kpSelectors.getKnowledgeTypeData(state),
  tagsSuggestionsList: kpSelectors.getTagsSuggestionsList(state),
  subjectSearchStatus: kpSelectors.getSubjectSearchStatus(state),
  subjectSearchLoadingStatus: kpSelectors.getSubjectSearchLoadingStatus(state),
  searchResults: caseMetadataSelectors.getSearchResults(state),
  totalSearchResults: caseMetadataSelectors.getTotalSearchResults(state),
  expertTagsIds: taxonomySelector.getExpertTagsIdsOnly(state),
  subjectTagsIds: taxonomySelector.getSubjectTagsIdsOnly(state),
  isSearchingCases: caseMetadataSelectors.getSearching(state),
  isPublishContentEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT),
});

const mapDispatchToProps = (dispatch) => ({
  searchRelatedCases: (payload) => dispatch({ type: SEARCH_CASES, payload }),
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch)
});

export const KPStep1 = connect(
  mapStateToProps,
  mapDispatchToProps
)(KPStep1Container);

export { KPStep1Container };